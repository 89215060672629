<template>
  <v-dialog :value="dialog" v-if="dialog" persistent max-width="500px" hide-overlay :attach="true" :content-class="'image-slider rd-custom-modal'">
    <v-card>
      <v-card-title >
        <h4></h4>
        <button class="close-btn" @click="$emit('close')">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
      </v-card-title>
      <v-card-text class="pa-0 flex-grow-1 overflow-y-auto text-center">
        <p>{{ dialogHeadline }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16" @click="$emit('close')">{{
          $t("confirmationDialog.cancel")
        }}</v-btn>
        <v-btn class="btn customBtn pattern-btn text-capitalize ml-2 white--text font-16" @click="$emit('confirm')">{{
          $t("confirmationDialog.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",

  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    dialogHeadline: {
      type: String,
      default: "",
    },
  },

  computed: {
    dialogStatus: {
      get: function () {
        return this.dialog;
      },
      set: function () {},
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
